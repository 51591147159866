import { useEffect } from 'react'

import { squirrelUrl } from '../../../shared/squirrel-url'
import { loadScript } from '../../../shared/utils/loadScript'
import { removeScriptTag } from '../../../shared/utils/remove-script-tag'

export type SquirrelScriptsData = {
  articlePage: {
    articleConfig?: {
      inlinePageScripts?: string[]
    }
  }
}

export const useSquirrelScript = (data: SquirrelScriptsData | undefined) => {
  useEffect(() => {
    const injectSquirrelScript =
      data?.articlePage?.articleConfig?.inlinePageScripts?.includes('squirrel')

    const squirrelWidgetScriptUrl = squirrelUrl()

    const loadSquirrelOnPageLoad = () => {
      loadScript({ src: squirrelWidgetScriptUrl })
    }

    if (injectSquirrelScript) {
      if (document.readyState === 'complete') {
        loadSquirrelOnPageLoad()
      } else {
        window.addEventListener('load', loadSquirrelOnPageLoad, false)

        return () => {
          removeScriptTag(squirrelWidgetScriptUrl)
          window.removeEventListener('load', loadSquirrelOnPageLoad)
        }
      }
    }
  }, [data])
}
