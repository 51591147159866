import { hasLockedTable } from './hasLockedTable'

export const getPageComponentsText = (articleBodyTemplate: Record<string, any>) => {
  const jsonString = JSON.stringify(articleBodyTemplate)
  const foundComponentsText: string[] = []

  if (jsonString.includes('"component":"SmartProductPicker"')) {
    foundComponentsText.push('SPP')
  }

  if (jsonString.includes('"component":"CardedDynamicTable"')) {
    foundComponentsText.push('MLP')
  }

  if (jsonString.includes('"component":"SquirrelEmbed"')) {
    foundComponentsText.push('squirrel widget')
  }

  // NSTL: non-standard table locked
  if (hasLockedTable(jsonString)) {
    foundComponentsText.push('NSTL')
  }

  return foundComponentsText.join('|')
}
