import { Helmet } from 'react-helmet-async'

// DFP/GAM panel used by CR, reviews single and multipage articles - lives below article body content
// https://pub.which.gpp.io/widgets/html-snippets/3
export const ArticleBodyDfp = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://www.which.co.uk/static/deliver/template-page-scripts/article-body-dfp.js"
          type="text/javascript"
          defer
        ></script>
      </Helmet>
      <div
        className="article-dfp-container"
        id="div-gpt-ad-6221233-1"
        style={{ textAlign: 'center' }}
      />
      <div
        className="article-dfp-container"
        id="div-gpt-ad-6221233-2"
        style={{ textAlign: 'center' }}
      />
      <div
        className="article-dfp-container"
        id="div-gpt-ad-6221233-3"
        style={{ textAlign: 'center' }}
      />
    </>
  )
}

// DFP/GAM panel used by news articles - lives below article body content
// https://pub.which.gpp.io/widgets/html-snippets/14
export const NewsArticleBodyDfp = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://www.which.co.uk/static/deliver/template-page-scripts/news-article-body-dfp.js"
          type="text/javascript"
          defer
        ></script>
      </Helmet>
      <div id="div-gpt-ad-4360980-1" />
      <div id="div-gpt-ad-4360980-2" />
      <div id="div-gpt-ad-4360980-3" />
    </>
  )
}

// DFP/GAM panel used by CR, reviews single and multipage articles - lives in the right sidebar
// https://pub.which.gpp.io/widgets/html-snippets/2
export const ArticleRhsDfp = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://www.which.co.uk/static/deliver/template-page-scripts/article-rhs-dfp.js"
          type="text/javascript"
          defer
        ></script>
      </Helmet>
      <div id="div-gpt-ad-6221233-4" className="article-dfp-container" />
    </>
  )
}

// DFP/GAM panel used by news articles - lives in the right sidebar
// https://pub.which.gpp.io/widgets/html-snippets/15
export const NewsArticleRhsDfp = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://www.which.co.uk/static/deliver/template-page-scripts/news-article-rhs-dfp.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <div>
        <div id="div-gpt-ad-6221233-2" className="article-dfp-container" />
      </div>
    </>
  )
}
