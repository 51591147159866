import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link, LinkButton, TypographyV2 as Typography } from '@which/seatbelt'
import { CrossIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import { ComparisonTableContext } from '../../../product-comparison/ComparisonTableContext'
import type { ToastContext } from '../../../product-listing/ReviewsTemplateContext'
import { ReviewsTemplateContext } from '../../../product-listing/ReviewsTemplateContext'
import styles from './ToastNotification.module.scss'

export const defaultState = {
  displayToast: false,
  error: false,
  mainText: '',
  linkText: '',
  linkUrl: undefined,
  onClick: undefined,
}

export const ToastNotification: React.FC = () => {
  const { state } = useContext(ReviewsTemplateContext)
  const { state: compareState } = useContext(ComparisonTableContext)
  const [toastConfig, setToastConfig] = useState<ToastContext>(defaultState)
  state.setToastContext = setToastConfig
  compareState.setToastContext = setToastConfig
  const { displayToast, error, mainText, linkText, linkUrl, onClick } = toastConfig
  const toastTimerIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

  const dismissToast = useCallback(() => {
    clearTimeout(toastTimerIdRef.current)
    setToastConfig({ ...defaultState, error })
  }, [setToastConfig, error])

  const prevDisplayToastRef = useRef(displayToast)

  useEffect(() => {
    if (displayToast && !prevDisplayToastRef.current) {
      if (toastTimerIdRef.current) {
        clearTimeout(toastTimerIdRef.current)
      }

      toastTimerIdRef.current = setTimeout(dismissToast, 5000)
    }

    prevDisplayToastRef.current = displayToast

    return () => {
      clearTimeout(toastTimerIdRef.current)
    }
  }, [dismissToast, displayToast])

  return (
    <div
      className={classnames(styles.sticky, displayToast && styles.visible)}
      data-testid="toast-notification"
    >
      <div className={classnames(styles.infoBar, error && styles.error)}>
        <div className={styles.textAndLink}>
          <Typography
            data-testid="toast-notification-text"
            textStyle="sb-text-interface-body-small-regular"
            aria-live="assertive"
            aria-atomic
          >
            {mainText}
          </Typography>
          {linkText && linkUrl && (
            <Link
              className={styles.link}
              textStyle="sb-text-interface-body-small-strong"
              data-which-id="toast-notification-link"
              data-testid="toast-notification-link"
              href={linkUrl}
            >
              {linkText}
            </Link>
          )}
          {linkText && onClick && (
            <LinkButton
              className={styles.link}
              textStyle="sb-text-interface-body-small-strong"
              data-which-id="toast-notification-link"
              data-testid="toast-notification-button"
              onClick={onClick}
            >
              {linkText}
            </LinkButton>
          )}
        </div>
        <button
          className={styles.close}
          onClick={dismissToast}
          onKeyPress={dismissToast}
          aria-label="Close"
        >
          <CrossIcon width={15} height={15} />
        </button>
      </div>
    </div>
  )
}
