import React from 'react'

export const generateSchemaFromSnippet = (htmlString: string, scriptKey?: string): JSX.Element[] => {
  const keyProps = scriptKey ? { key: scriptKey } : {}

  return htmlString?.includes('<script type="application/ld+json"')
    ? [
        <script {...keyProps} type="application/ld+json">
          {htmlString.replace(/<\/?script\b[^>]*>/g, '')}
        </script>,
      ]
    : []
}
