import './template-styles/ArticlePage.scss'

import React from 'react'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import type { Author, ImageWithSources, ReviewsAdviceMultiQuery } from '../../generated/frontend'
import { useReviewsAdviceMultiQuery } from '../../generated/frontend'
import { useBackStack } from '../../shared/BrowserBackStackProvider'
import { AffiliateDisclaimer } from '../../shared/components/AffiliateDisclaimer/AffiliateDisclaimer'
import { AssociatedArticles } from '../../shared/components/AssociatedArticles/AssociatedArticles'
import { BackToTop } from '../../shared/components/BackToTop/BackToTop'
import { Breadcrumb } from '../../shared/components/Breadcrumb'
import { ErrorComponent, PageError } from '../../shared/components/Error'
import { HideOnArticleScroll } from '../../shared/components/HideOnScroll/HideOnArticleScroll'
import { Loader } from '../../shared/components/Loader'
import { LocalFooter } from '../../shared/components/LocalFooter/LocalFooter'
import { PromoBlock } from '../../shared/components/PromoBlock/PromoBlock'
import { Redirect } from '../../shared/components/Redirect'
import { ResubscribeBanner } from '../../shared/components/ResubscribeBanner/ResubscribeBanner'
import { SharingOptions } from '../../shared/components/Sharing/SharingOptions'
import { getDataLayerScripts } from '../../shared/data-layer'
import { getDFPScripts } from '../../shared/dfp'
import { GridArticle, GridItemArticle } from '../../shared/grids'
import { useBaseMetaTags } from '../../shared/hooks/useBaseMetaTags'
import { useFullUrl } from '../../shared/hooks/useFullUrl'
import { articleBodyRenderer } from '../../shared/renderers/article-body-renderer'
import type { PageInfo } from '../../shared/types/PageInfo'
import { usePageProps } from '../../shared/usePageProps'
import { getUpdatedAtDate } from '../../shared/utils/get-updated-at-date'
import { getPreviousUrl } from '../../shared/utils/getPreviousUrl/getPreviousUrl'
import { removeFalsy } from '../../shared/utils/remove-falsy'
import { removeUrlQueryStringAndFragment } from '../../shared/utils/remove-url-query-string-and-fragment'
import { PageWrapperWithMeta } from '../../shared/wrappers/PageWrapperWithMeta'
import { ArticleHero } from '../article/components/ArticleHero/ArticleHero'
import { AuthorWrapper } from '../article/components/Author/AuthorWrapper'
import { Header } from '../article/components/Header/Header'
import { IntroParagraph } from '../article/components/IntroParagraph/IntroParagraph'
import { WhichConnectMessage } from '../article/components/WhichConnectMessage/WhichConnectMessage'
import { ToastNotification } from '../reviews/shared/components/ToastNotification'
import { ArticleTemplateContext } from './ArticleTemplateContext'
import { CompareTrayWrapper } from './components/CompareTrayWrapper/CompareTrayWrapper'
import { JumpLinks } from './components/JumpLinks/JumpLinks'
import { dynamicTableDatalayer } from './dynamic-table-datalayer'
import type { SquirrelScriptsData } from './hooks/use-squirrel-script'
import { useSquirrelScript } from './hooks/use-squirrel-script'
import { ArticleBodyDfp, ArticleRhsDfp } from './page-snippets/dfp'
import { UserFeedbackIframe } from './page-snippets/user-feedback-iframe'
import { getPageComponentsText } from './utils/get-components-text'
import { generateSchema } from './utils/schema'

const ReviewsAdviceMultiPage: React.FC = () => {
  const { pathname, search } = useLocation()
  const { context = '' } = usePageProps()
  const { loading, error, data } = useReviewsAdviceMultiQuery({
    variables: { url: `${pathname}${search}`, context },
  })

  const { getFullUrl } = useFullUrl()
  const backStack = useBackStack()
  const previousUrl = getPreviousUrl({ backStack })

  const { getBaseMetaTags } = useBaseMetaTags()

  useSquirrelScript(data as SquirrelScriptsData)

  const resubscribeBannerFlag = useFeatureIsOn('resubscribe-banner')
  const gbTestValue = useFeatureValue('ctx-011-contextualised-newsletter', '')

  const { userAccessState } = usePageProps()
  const transformTypeDecision = userAccessState?.transformTypeDecision ?? ''
  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(transformTypeDecision)

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data?.articlePage) {
    return <PageError pageName="Article Page" />
  }

  const { articlePage } = data

  if (articlePage.__typename === 'Redirect') {
    return <Redirect to={articlePage.redirectUrl} />
  }

  const {
    articleBodyTemplate,
    articleConfig,
    authors,
    breadcrumb,
    header,
    heroImage,
    introParagraph,
    jumpLinks,
    meta,
    multiPageGuide,
    optionalArticleData,
    sharingData,
    showResubBanner,
  } = articlePage

  const baseMetaTags = getBaseMetaTags({
    meta,
    context,
    imageUrl: heroImage?.src,
    twitterCard: 'summary_large_image',
  })
  const metaTags = getMetaTags({
    articlePage,
    baseMetaTags,
    urls: { currentUrl: getFullUrl(), previousUrl },
    gbTestValue,
  })
  const moneyPromoBlock = optionalArticleData?.components?.moneyPromoBlock
  const consumerRightsAndMoneyLocalFooter = optionalArticleData?.components?.localFooter

  const handleShowMoreOnJumplinkClick = () => {
    const showMoreBtn = document.querySelector<HTMLButtonElement>(
      '[data-testid="show-more-button"]'
    )

    if (showMoreBtn) {
      showMoreBtn.click()
    }
  }

  return (
    <ArticleTemplateContext.Provider
      value={{
        meta,
      }}
    >
      <div ref={domUpdates} data-testid="template-portal" data-template-type="reviews-advice-multi">
        {renderResubscribeBanner(resubscribeBannerFlag, transformTypeDecision) && (
          <ResubscribeBanner showBanner={showResubBanner} />
        )}
        <Breadcrumb {...breadcrumb} />
        <PageWrapperWithMeta metaTags={metaTags}>
          <GridArticle>
            <GridItemArticle area="header">
              <AffiliateDisclaimer affiliateDisclaimer={articleConfig?.affiliateDisclaimer} />
              <Header {...header} />
            </GridItemArticle>
            <GridItemArticle area="author">
              <AuthorWrapper authors={authors} />
            </GridItemArticle>
            <GridItemArticle area="hero">
              <ArticleHero heroImage={heroImage as ImageWithSources} />
            </GridItemArticle>
            <GridItemArticle area="jumplinks">
              <JumpLinks handleOnClick={handleShowMoreOnJumplinkClick} links={jumpLinks} />
            </GridItemArticle>
            <GridItemArticle area="share" className="gridarea-share">
              <HideOnArticleScroll>
                <SharingOptions sharingData={sharingData} />
              </HideOnArticleScroll>
            </GridItemArticle>
            <GridItemArticle area="body" className="gridarea-body">
              <IntroParagraph text={introParagraph} />
              {articleBodyRenderer(articleBodyTemplate)}
              <WhichConnectMessage showMessage={articleConfig?.whichConnectMessage} />
              {moneyPromoBlock && <PromoBlock {...moneyPromoBlock} />}
              <div className="related-footer">
                <AssociatedArticles
                  componentType="multi-page-guide"
                  position="bottom"
                  className="associated-articles"
                  useShowMoreComponent={true}
                  {...multiPageGuide}
                />
              </div>
              <UserFeedbackIframe />
              <ArticleBodyDfp />
              <SharingOptions sharingData={sharingData} orientation="vertical" />
              <div className="back-to-top-wrapper">
                <BackToTop />
              </div>
            </GridItemArticle>
            <GridItemArticle area="related" className="gridarea-related">
              <aside className="sidebar">
                {moneyPromoBlock && <PromoBlock {...moneyPromoBlock} />}
                <AssociatedArticles
                  componentType="multi-page-guide"
                  position="right"
                  useShowMoreComponent={true}
                  {...multiPageGuide}
                />
                {<ArticleRhsDfp />}
              </aside>
            </GridItemArticle>
            {consumerRightsAndMoneyLocalFooter && (
              <GridItemArticle area="footer">
                <LocalFooter data={consumerRightsAndMoneyLocalFooter} />
              </GridItemArticle>
            )}
          </GridArticle>
        </PageWrapperWithMeta>
      </div>
      {isPaidMember && (
        <CompareTrayWrapper taxonomySlug={meta?.taxonomyHierarchy?.category?.slug} />
      )}
      <ToastNotification />
    </ArticleTemplateContext.Provider>
  )
}

export default ReviewsAdviceMultiPage

const domUpdates = () => {
  dynamicTableDatalayer()
}

const renderResubscribeBanner = (featureFlag: boolean, transformTypeDecision: string): boolean => {
  if (
    !featureFlag ||
    !transformTypeDecision ||
    transformTypeDecision !== 'AUTHENTICATED_NO_ACCESS'
  ) {
    return false
  }

  return true
}

export type ReviewsAdviceMultiArticleData = ReviewsAdviceMultiQuery['articlePage'] & {
  __typename: 'ArticlePage'
}

const getMetaTags = ({
  articlePage: { meta, articleBodyTemplate, heroImage, authors, articleConfig },
  baseMetaTags,
  urls,
  gbTestValue,
}: {
  articlePage: ReviewsAdviceMultiArticleData
  baseMetaTags: JSX.Element[]
  urls: { currentUrl: string; previousUrl?: string }
  gbTestValue: string
}) => {
  const authorsMeta = authors.length
    ? [<meta key="author" name="author" content={authors.map(({ name }) => name).join(', ')} />]
    : []

  const keywords = meta.keywords.length
    ? [<meta key="keywords" name="keywords" content={meta.keywords.join(', ')} />]
    : []

  const basePageInfo: PageInfo = {
    pageType: 'mpg-article',
    vertical: meta.taxonomyHierarchy?.vertical.slug,
    sub_vertical: meta.taxonomyHierarchy?.subVertical?.slug,
    super_category: meta.taxonomyHierarchy?.superCategory?.slug,
    category: meta.taxonomyHierarchy?.category?.slug,
    content_id: meta.contentId,
    isVideoArticle: false,
  }

  // data layer
  const dataLayerPageInfo: PageInfo = {
    ...basePageInfo,
    wcdPageUrl: removeUrlQueryStringAndFragment(urls.currentUrl),
    ...(urls.previousUrl && { previousWcdPageUrl: urls.previousUrl }),
    keywords: meta.keywords?.length ? meta.keywords.join(' | ') : '',
    tags: meta.keywords?.length ? meta.keywords.join(' | ') : '',
    author: authors.length ? authors.map(({ name }) => name).join(' | ') : '',
    pageComponents: getPageComponentsText(articleBodyTemplate),
    contentPublishedDate: getUpdatedAtDate(meta.publishedDate),
    contentUpdatedDate: getUpdatedAtDate(meta.updatedDateTime),
    systemContentPublishedDate: getUpdatedAtDate(meta.systemContentPublishedDate),
    systemContentUpdatedDate: getUpdatedAtDate(meta.systemContentUpdatedDate),
    glideCurationDate: meta.glideCurationDate,
    frequencyOfUpdate: meta.frequencyOfUpdate,
    significantUpdate: meta.significantUpdate,
    articleType: articleConfig.articleType,
    content_type: 'advice',
  }
  const dataLayer = getDataLayerScripts([...(meta.dataLayer || []), removeFalsy(dataLayerPageInfo)])

  // doubleclick for publishers
  const dfpMeta = getDFPScripts(meta.dataLayer, {
    ...basePageInfo,
    keywords: meta.keywords,
    tags: meta.keywords,
    GB_test_value: gbTestValue,
  })

  // schema for SEO
  const schema = generateSchema({
    meta,
    authors: authors as Author[],
    imageList: heroImage?.renditions,
  })

  return baseMetaTags.concat(
    authorsMeta,
    keywords,
    dataLayer,
    dfpMeta,
    schema,
    <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
  )
}
