import type { Author, Meta } from '../../../generated/frontend'
import { generateSchemaFromSnippet } from '../../../shared/utils/get-schema-from-snippet'
import { getTimestampToIso } from '../../../shared/utils/timestamp-to-iso'

type SchemaParams = {
  meta: Meta
  authors: Author[]
  imageList: string[]
  schemaType?: 'Article' | 'NewsArticle'
}

export const generateSchema = ({
  meta,
  authors,
  imageList,
  schemaType = 'Article',
}: SchemaParams) => {
  const schemas: JSX.Element[] = []
  const baseSchema = (
    <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "${schemaType}",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${meta.canonical}"
          },
          "headline": "${meta.title}",
          "description": "${meta.desc}",
          ${imageList ? `"image": ${JSON.stringify(imageList.filter(Boolean), null, 2)},` : ''}
          ${
            authors[0]?.name
              ? `
          "author": {
            "@type": "Person",
            "name": "${authors[0].name}",
            "url": "https://www.which.co.uk${
              authors[0].slug === null
                ? '/about-which/who-we-are'
                : `/news/author/${authors[0].slug}`
            }"
          },`
              : ''
          }
          "publisher": {
            "@type": "Organization",
            "name": "Which?",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.which.co.uk/favicon-512.png",
              "width": 512,
              "height": 512
            }
          },
          "datePublished": "${getTimestampToIso(meta.publishedDateTime)}",
          "dateModified": "${getTimestampToIso(meta.updatedDateTime)}"
        }
      `}</script>
  )

  schemas.push(baseSchema)

  if (meta.reviewSchema) {
    const reviewSchema = generateSchemaFromSnippet(meta.reviewSchema)

    return schemas.concat(reviewSchema)
  }

  return schemas
}
