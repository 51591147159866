import type { FunctionComponent } from 'react'
import { useCallback, useContext, useEffect } from 'react'
import { dynamicDatalayerPush } from '@which/shared'

import { ReviewsCompareTray } from '../../../../shared/components/ReviewsCompareTray/ReviewsCompareTray'
import { CompareTrayContext } from '../../../../shared/contexts/CompareTrayContext'

type Props = {
  taxonomySlug: string
}

export const CompareTrayWrapper: FunctionComponent<Props> = ({ taxonomySlug }) => {
  const {
    setTaxonomySlug,
    compareTrayItems,
    taxonomySlug: currentTaxonomySlug,
    removeHandler,
    removeAllProducts,
  } = useContext(CompareTrayContext)
  useEffect(() => {
    if (taxonomySlug && taxonomySlug !== currentTaxonomySlug) {
      setTaxonomySlug(taxonomySlug)
    }
  }, [taxonomySlug, setTaxonomySlug, currentTaxonomySlug])

  const compareButtonClickHandler = useCallback(() => {
    dynamicDatalayerPush({
      eventAction: 'Compare - Compare Tray',
      eventCategory: 'Product Compare',
      eventLabel: '',
    })
  }, [])

  if ((compareTrayItems?.length ?? 0) <= 0) {
    return null
  }

  return (
    <ReviewsCompareTray
      taxonomySlug={currentTaxonomySlug}
      products={compareTrayItems}
      removeHandler={removeHandler}
      removeAllHandler={removeAllProducts}
      compareHandler={compareButtonClickHandler}
      skipToContent={{
        text: 'Return to article',
        selector: 'h1[data-testid="headline"]',
      }}
    />
  )
}
