// currently used by reviews single and multipage, news and CR article types - lives below article body content
// https://pub.which.gpp.io/widgets/html-snippets/6

export const UserFeedbackIframe = () => (
  <iframe
    loading="lazy"
    data-src="https://which.qualtrics.com/jfe/form/SV_0GPk8mF74pygt3o"
    name="user-feedback-iframe"
    title="user feedback form"
    style={{ width: '100%', height: '175px', border: 'none', marginTop: '25px' }}
    className="optanon-category-C0002 user-feedback-iframe"
  />
)
